<template>
    <section>
        <p class="text-black f-500 f-20 mb-2">
            Modelos vehículos
        </p>
        <div class="row mx-0">
            <div v-if="verGrafica" class="col-12 px-0">
                <echart :options="vehiculos" />
            </div>
            <div v-else class="col-12 px-0 text-center">
                <img class="obj-cover" src="/img/no-imagen/no-grafica.png" />
            </div>
        </div>        
    </section>
</template>
<script>
import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
export default {
    props: {
        data: {
            type: Array,
            default: [],
        },
    },
    data(){
        return {
            verGrafica: false,
            vehiculos: {
                legend: {
                    show: true,
                    data: [
                        { name: 'Carros', icon: 'square', textStyle: '#000000', },
                        { name: 'Camionetas', icon: 'square', textStyle: '#000000', },
                        { name: 'Motos', icon: 'square', textStyle: '#000000', }
                    ]
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        let carros = params[0]
                        let camionetas = params[1]
                        let motos = params[2]
                        return `${params[0].axisValue}: <br />
                        ${carros.marker} ${carros.value} Carros <br />
                        ${camionetas.marker} ${camionetas.value} Camionetas <br />
                        ${motos.marker} ${motos.value} Motos <br />
                        ` 
                    },
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#DFE4E8"
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#DFE4E8"
                        }
                    },
                    axisLabel: {
                        color: "#637381"
                    }
                },
                yAxis: {
                    type: 'value',
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#DFE4E8"
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#DFE4E8"
                        }
                    },
                    axisLabel: {
                        color: "#637381"
                    }
                },
                series: [
                    {   
                        name: 'Carros',
                        data: [],
                        type: 'bar',
                        stack: 'genero',
                        itemStyle: {
                            color: '#80DCFF'
                        }
                    },
                    {   
                        name: 'Camionetas',
                        data: [],
                        type: 'bar',
                        stack: 'genero',
                        itemStyle: {
                            color: '#FFB2C2'
                        }
                    },
                    {   
                        name: 'Motos',
                        data: [],
                        type: 'bar',
                        stack: 'genero',
                        itemStyle: {
                            color: '#B2B2FF'
                        }
                    }
                ]
            }
        }
    },

    watch:{
        data(){
            this.mapearData();
        },
    },

    mounted(){
        this.mapearData();
    },

    methods: {
        mapearData(){
            let data = this.data;
            let xAxis = [];
            let carros = [];
            let camionetas = [];
            let motos = [];
            let contadore = data.length;

            if(parseInt(contadore) < 1)return this.verGrafica = false;

            for(let i = 0; i < contadore; i++){
                xAxis = [...xAxis, data[i].modelo];
                carros = [...carros, data[i].carros];
                camionetas = [...camionetas, data[i].camionetas];
                motos = [...motos, data[i].motos];
            }

            this.vehiculos.xAxis.data = xAxis;
            this.vehiculos.series[0].data = carros;
            this.vehiculos.series[1].data = camionetas;
            this.vehiculos.series[2].data = motos;

            this.verGrafica = true;
        },
    },
}
</script>